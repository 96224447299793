import {
  readFileWithLog,
  readOrFetchFromPublicWithLog,
  writeFileToPublicWithLog,
  writeFileWithLog,
} from './fs-helpers';
import { getCleanedSlug } from '../contentTypes/utils';

export const implementCaching = async ({
  fileName,
  fn,
  hardRefresh = false,
  logPrefix = 'cachingHelpers',
  writeToPublic = false,
} = {}) => {
  const filePath = `./cache/${fileName}`;

  let cachedJson = readFileWithLog({
    defaultValue: null,
    filePath,
    logPrefix,
  });

  if (!cachedJson || hardRefresh) {
    cachedJson = await fn();

    writeFileWithLog({
      content: JSON.stringify(cachedJson),
      filePath,
      logPrefix,
    });
  }

  if (writeToPublic) {
    writeFileToPublicWithLog({
      filePath: fileName,
      content: JSON.stringify(cachedJson),
      logPrefix,
    });
  }

  return cachedJson;
};

// Returns all the alternative pages
export const getAlternativePages = async ({
  contentType,
  logPrefix = 'cachingHelpers',
  slug: currentSlug,
}) => {
  const slug = getCleanedSlug({
    slug: currentSlug,
  });

  const json = await readOrFetchFromPublicWithLog({
    defaultValue: [],
    filePath: 'all-pages.json',
    logPrefix,
    noSuccessLog: true,
  });

  const findLocale = () => {
    return (json ?? []).find((groupedPages) => {
      return groupedPages.find(({ path, postType }) => {
        const searchPath = `/${slug}`;
        const intlRegex = new RegExp(`^/[a-z]{2}-[A-Z]{2}${searchPath}$`);

        return (
          postType === contentType &&
          (searchPath === path || intlRegex.test(searchPath))
        );
      });
    });
  };

  const foundPages = findLocale() ?? [];

  return foundPages.map(({ locale, path }) => ({
    locale,
    path,
  }));
};
